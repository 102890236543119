const message: any = {
  log_in: 'Login English',
  email: 'E-mail',
  log_out: 'Log out',
  back: 'Back',
  next: 'Next',
  choose: 'Choose',
  delete: 'Delete',
  confirm: 'Confirm',
  copy: 'Copy',
  yes: 'Yes',
  save: 'Save',
  ok: 'Ok',
  add: 'Add',
  cancel: 'Cancel',
  link_copied: 'Link copied',
  please_choose_language_of_invite: 'Please choose language of invite',
  join_our_fatflow_app_german: 'Laden Sie unsere Flatflow-App im App Store or Google Play Store mit folgendem Link auf ihr Handy:',
  join_our_fatflow_app: 'Download our Flatflow app in the App Store or Google Play Store with the following link on your cell phone:',
  are_you_sure: 'Are you sure?',
  actions: 'Actions',
  actions_hint: 'Overview of all possible actions',
  login_form: {
    password: 'Password',
    get_the_code: 'Get code',
    please_check_your_mail: 'Please check your e-mail and enter the received code',
    code: 'Input code',
    resend_text: 'Did not receive the code?',
    resend: 'Resend code',
    login: 'Login',
    sent_code_again_text: 'The code has been sent to your e-mail again'
  },
  first_login: {
    title: 'Welcome to Flatflow',
    text: 'Please add some information about yourself to complete the registration'
  },
  nav: {
    dashboard: 'Dashboard',
    property: 'Property',
    integrations: 'Integrations',
    settings: 'Settings',
    smart_devices: 'Smart devices'
  },
  settings: {
    title: 'Settings',
    language: 'Language',
    edit: 'Edit',
    first_name: 'First name',
    last_name: 'Last name',
    phone_number: 'Phone number'
  },
  integrations: {
    title: 'Integrations',
    add_integration: 'Add integration',
    client_id: 'Client Id',
    client_secret: 'Client Secret',
    more_info: 'More info',
    search_by: 'Search by E-mail or Account Type',
    account_type: 'Account type',
    unlink_account: 'Unlink account',
    action_hint: 'Unlink existing accounts'
  },
  property: {
    property: 'Property',
    add_building: 'Add building',
    unit_overview: 'Unit overview',
    buildings_overview: 'Buildings overview',
    add_new_unit: 'Add new unit',
    units: 'Units',
    sure_delete_building: 'Are you sure you want to delete the building?',
    search: 'Search by address ',
    create_edit_slots: 'Create/edit slots',
    first_day: 'First day of visit',
    first_day_hint: 'Date on which the visits should start',
    available_days: 'Available days',
    available_days_hint: 'Days on which visits can be carried out',
    daily_availability: 'Daily availability',
    daily_availability_hint: 'Time during which visits can be carried out',
    select_all_or_custom_days: 'Select all or custom days',
    beginning_time: 'Beginning time',
    duration: 'Duration, min',
    end_time: 'End time',
    create_slots: 'Create slots',
    discard: 'Discard',
    add_unit: 'Add Unit',
    edit_unit: 'Edit Unit',
    unit_information: 'Unit information',
    add_unit_barriers: 'Add unit barriers',
    add_unit_barrier: 'Add unit barrier',
    orientation_barrier: 'Orientation barrier',
    smart_lock: 'Smart lock',
    next: 'Next',
    custom_unit_name_reference: 'Custom Unit name (for your reference)',
    delete_unit: 'Are you sure you want to delete the unit?',
    choose_unit: 'Choose unit',
    unit_details: 'Additional unit details (optional)',
    unit_details_hint: 'Option to provide additional information on the flat',
    enter_a_link: 'Enter a link with unit details',
    viewing_options: 'Viewing options',
    viewing_options_hint: 'Further options and settings for the visits',
    unpublish: 'Unpublish',
    publish: 'Publish',
    link_with_unit_details: 'Link with unit details',
    visit_barriers: 'Visit barriers',
    barriers_for_smart_handovers: 'Barriers for smart handovers',
    post_handover_barriers: 'Post-handover barriers',
    slots: 'Slots',
    want_to_add_visit_slots_now: 'You want to add visit slots now?',
    are_you_sure_you_want_to: 'Are you sure?',
    german: 'German',
    english: 'English',
    combination: 'Combination',
    thanks_for_the_interest_in_the_property: 'Thanks for the interest in the property. In order to conduct a live visit please follow this link:',
    building_address: 'Building address',
    unit_name: 'Unit name',
    visit_link: 'Visit link',
    sh_link: 'SH link',
    units_listed_of_units_total: '# of units listed/# of units total',
    more_info_about_building: 'More info about building',
    edit_building: 'Edit building',
    create_or_edit_slots: 'Create or edit slots',
    more_info_about_unit: 'More info about unit',
    copy_unit: 'Copy unit',
    please_add_all_barriers: 'Please add all barriers / visit steps that could be useful for multiple units in the building. Only individual unit barriers such as apartment door should be added as unit barrier. The order of the common barriers at this step is irrelevant and can be changed later.',
    add_unit_visit_barriers_choose_order: 'Add unit visit barriers & choose order',
    please_drag_and_drop_barriers: 'Please drag and drop common and unit barriers from the left into the visit flow in the order you wish the flow to be conducted',
    common_barriers: 'Common barriers',
    unit_barriers: 'Unit barriers',
    visit_flow: 'Visit flow',
    different_selection: 'Different selection/order for smart handovers (ignore if no smart handovers should be conducted)',
    handover_flow: 'Handover flow',
    unit_overview_hint: 'Overview of all units',
    buildings_overview_hint: 'Overview of all buildings',
    copy_units: 'Copy unit',
    // new
    please_set_the_visit_slots_before_publishing_this_unit: 'Please set the visit slots before publishing this unit',
    edit_slots: 'Edit slots',
    update_slots_text: 'You already have visit slots. If you click “Next”, they will be displayed for handover as well. If you want to change slots, then click “Update slots”',
    want_to_edit_visit_slots_now: 'You want to edit visit slots now?'
  },
  building: {
    discard: 'Discard',
    barrier_title: 'Barrier title *',
    add_new: 'Add Building',
    barriers_title: 'Common barriers / visit steps',
    address_selection: 'Address selection',
    start_typing: 'Start typing your street name*',
    address: 'Address',
    move_pin: 'Please move the pin to the exact location of your most outer barrier as accurate as possible',
    save_and_add_unit: 'Save and Add unit',
    change_address: 'Change address',
    confirm_pin: 'Confirm',
    building_barriers: 'Common Barriers / Visit Steps',
    smart_lock: 'Smart lock',
    check_connection: 'Check connection',
    add_barrier: 'Add a barrier',
    pictures_and_description: 'Pictures and description of the building',
    upload: 'Upload',
    barrier_description: 'Barrier description *',
    barrier_description_prompt: 'e.g., "Open this door and go to the third floor afterwards" or "You will find this door by entering the parking lot of the building"',
    barrier_add_image_title: 'Add photo or video',
    barrier_add_image: 'Only jpg, png, mov or mp4',
    orientation_barrier: 'Orientation barrier'
  },
  dashboard: {
    upcoming: 'Upcoming',
    history: 'History',
    all_planned: 'All planned:',
    no_results_found: 'No results found',
    address: 'Address',
    create_slots: 'Create slots',
    my_appointments: 'My appointments',
    my_smart_devices: 'My smart devices',
    delete_appointments: 'Are you sure you want to delete appointments?',
    search_appointments: 'Search by address, visitor or unit name',
    search_device: 'Search by name, device type or address',
    name: 'Name',
    barrier_title: 'Barrier title',
    device_type: 'Device type'
  }
}

export default message
