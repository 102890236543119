const message: any = {
  log_in: 'Login',
  email: 'E-Mail',
  log_out: 'Abmelden',
  back: 'Zurück',
  next: 'Weiter',
  choose: 'Auswählen',
  delete: 'Löschen',
  confirm: 'Bestätigen',
  copy: 'Kopieren',
  yes: 'Ja',
  save: 'Speichern',
  ok: 'Ok',
  add: 'Hinzufügen',
  cancel: 'Abbrechen',
  link_copied: 'Link kopiert',
  please_choose_language_of_invite: 'Bitte wählen Sie die Sprache der Einladung aus',
  join_our_fatflow_app_german: 'Laden Sie unsere Flatflow-App im App Store or Google Play Store mit folgendem Link auf ihr Handy:',
  join_our_fatflow_app: 'Download our Flatflow app in the App Store or Google Play Store with the following link on your cell phone:',
  are_you_sure: 'Sind Sie sicher?',
  actions: 'Aktionen',
  actions_hint: 'Überblick weiterer Funktionen & Aktionen',
  login_form: {
    password: 'Passwort',
    get_the_code: 'Code anfragen',
    please_check_your_mail: 'Bitte prüfen Sie Ihr E-Mail-Postfach und geben den Bestätigungscode ein',
    code: 'Bestätigungscode',
    resend_text: 'Bestätigungscode nicht erhalten?',
    resend: 'Erneut senden',
    login: 'Anmelden',
    sent_code_again_text: 'Der Bestätigungscode wurde erneut an Ihre E-Mail gesendet'
  },
  first_login: {
    title: 'Willkommen bei Flatflow',
    text: 'Tragen Sie bitte Ihre Informationen ein, um die Anmeldung abzuschließen'
  },
  nav: {
    dashboard: 'Dashboard',
    property: 'Einheiten',
    integrations: 'Integrationen',
    settings: 'Einstellungen',
    smart_devices: 'Smart-Devices'
  },
  settings: {
    title: 'Einstellungen',
    language: 'Sprache',
    edit: 'Bearbeiten',
    first_name: 'Vorname',
    last_name: 'Nachname',
    phone_number: 'Telefonnummer'
  },
  integrations: {
    title: 'Integrationen',
    add_integration: 'Konto hinzufügen',
    client_id: 'Kunden ID',
    client_secret: 'Kunden Code',
    more_info: 'Zusätzliche Informationen',
    search_by: 'Nach E-Mail oder Konto Typ suchen',
    account_type: 'Konto Typ',
    unlink_account: 'Konto entfernen',
    action_hint: 'Integrierte Konten entfernen'
  },
  property: {
    property: 'Einheiten',
    add_building: 'Gebäude hinzufügen',
    unit_overview: 'Wohnungsüberblick',
    buildings_overview: 'Gebäudeüberblick',
    add_new_unit: 'Wohnung hinzufügen',
    units: 'Wohnungen',
    sure_delete_building: 'Sind Sie sicher, dass Sie das Gebäude löschen wollen?',
    search: 'Nach Adresse suche ',
    create_edit_slots: 'Slots erstellen/bearbeiten',
    first_day: 'Besichtigungsbeginn',
    first_day_hint: 'Datum an dem die Besichtigungen starten sollen',
    available_days: 'Verfügbare Tage',
    available_days_hint: 'Tagen an den Besichtigungen durchgeführt werden können',
    daily_availability: 'Zeitraum',
    daily_availability_hint: 'Uhrzeit in den Besichtigungen durchgeführt werden können',
    select_all_or_custom_days: 'Alle oder benutzerdefinierte Tage auswählen',
    beginning_time: 'Startzeit',
    duration: 'Dauer, Min',
    end_time: 'Endzeit',
    create_slots: 'Slots erstellen',
    discard: 'Abbrechen',
    add_unit: 'Wohnung hinzufügen',
    edit_unit: 'Wohnung bearbeiten',
    unit_information: 'Informationen',
    add_unit_barriers: 'Barriere hinzufügen',
    add_unit_barrier: 'Barriere hinzufügen',
    orientation_barrier: 'Orientierungshilfe',
    smart_lock: 'Smart-Lock',
    next: 'Weiter',
    custom_unit_name_reference: 'Objektnummer (Nur für Sie sichtbar zur Identifizierung der Wohnungen)',
    delete_unit: 'Sind Sie sicher, dass Sie die Wohnung löschen möchten?',
    choose_unit: 'Wohnung auswählen',
    unit_details: 'Weitere Informationen (Optional)',
    unit_details_hint: 'Weitere Informationen zur Wohnung',
    enter_a_link: 'Fügen Sie einen Link zum Exposé der Wohnung ein',
    viewing_options: 'Besichtigungsoptionen',
    viewing_options_hint: 'Weitere Optionen und Einstellungen zur Durchführung der Besichtigungen',
    unpublish: 'Deaktivieren',
    publish: 'Veröffentlichen',
    link_with_unit_details: 'Link mit Details zur Wohnung',
    visit_barriers: 'Besichtigungsbarrieren',
    barriers_for_smart_handovers: 'Barrieren für smarte Übergaben',
    post_handover_barriers: 'Barrieren nach der Übergabe',
    slots: 'Slots',
    want_to_add_visit_slots_now: 'Möchten Sie Besichtigungstermine hinzufügen?',
    are_you_sure_you_want_to: 'Sind Sie sicher?',
    german: 'Deutsch',
    english: 'Englisch',
    combination: 'Kombination',
    thanks_for_the_interest_in_the_property: 'Vielen Dank für Ihr Interesse an dieser Wohnung. Um eine Besichtigung zu buchen, folgen Sie bitte diesem Link auf ihrem Handy:',
    building_address: 'Adresse',
    unit_name: 'Wohnung',
    visit_link: 'Besichtigungslink',
    sh_link: 'Übergabelink',
    units_listed_of_units_total: '# veröffentlichten Wohnung / Wohnungen Gesamt',
    more_info_about_building: 'Weitere Gebäudeinformationen',
    edit_building: 'Gebäude bearbeiten',
    create_or_edit_slots: 'Slots erstellen & bearbeiten',
    more_info_about_unit: 'Weitere Wohnungsinformationen',
    copy_unit: 'Wohnung kopieren',
    please_add_all_barriers: 'Bitte fügen Sie hier alle Barrieren / Besuchsschritte ein, die alle Wohnungen im Gebäude relevant sind. Barrieren für einzelne Wohnungen, wie z.B. die Wohnungstür, sollten Wohnungsbarrieren hinzugefügt werden. Die Reihenfolge der Barrieren ist in diesem Schritt irrelevant und kann später angepasst werden.',
    add_unit_visit_barriers_choose_order: 'Barrieren auswählen & Reihenfolge festlegen',
    please_drag_and_drop_barriers: 'Bitte ziehen Sie die Barrieren (Übergreifende und wohnungsspezifische) von links in gewünschter Reihenfolge in das rechte Feld "Besichtigungsschritte".',
    common_barriers: 'Übergreifende Barrieren',
    unit_barriers: 'Wohnungsbarrieren',
    visit_flow: 'Besichtigungsschritte',
    different_selection: 'Unterschiedliche Auswahl/Reihenfolge für smarte Übergaben (Bitte ignorieren, falls keine smarten Übergaben durchgeführt werden sollen)',
    handover_flow: 'Übergabeablauf',
    unit_overview_hint: 'Überblick über alle Wohnungen',
    buildings_overview_hint: 'Überblick über alle Gebäude',
    copy_units: 'Wohnung kopieren',
    // new
    please_set_the_visit_slots_before_publishing_this_unit: 'Please set the visit slots before publishing this unit',
    edit_slots: 'Edit slots',
    update_slots_text: 'You already have visit slots. If you click “Next”, they will be displayed for handover as well. If you want to change slots, then click “Update slots”',
    want_to_edit_visit_slots_now: 'You want to edit visit slots now?'
  },
  building: {
    discard: 'Abbrechen',
    barrier_title: 'Barriere-Titel *',
    add_new: 'Gebäude hinzufügen',
    barriers_title: 'Übergreifende Barrieren / Besuchsschritte',
    address_selection: 'Auswahl der Adresse',
    start_typing: 'Geben Sie bitte Ihren Straßennamen ein*',
    address: 'Adresse',
    move_pin: 'Bitte schieben Sie den Pin so genau wie möglich auf das Gebäude',
    save_and_add_unit: 'Speichern und Wohnung hinzufügen',
    change_address: 'Adresse ändern',
    confirm_pin: 'Bestätigen',
    building_barriers: 'Übergreifende Barrieren / Besuchsschritte',
    smart_lock: 'Smarte Schlösser',
    check_connection: 'Verbindung prüfen',
    add_barrier: 'Barriere hinzufügen',
    pictures_and_description: 'Bilder und Beschreibung des Gebäudes',
    upload: 'Hochladen',
    barrier_description: 'Beschreibung der Barriere *',
    barrier_description_prompt: 'z. B. "Öffnen Sie diese Tür und gehen Sie anschließend in den dritten Stock" oder "Sie finden diese Tür, wenn Sie den Parkplatz des Gebäudes betreten"',
    barrier_add_image_title: 'Foto oder Video hinzufügen',
    barrier_add_image: 'Nur jpg, png, mov ofrt mp4',
    orientation_barrier: 'Orientierungshilfe'
  },
  dashboard: {
    upcoming: 'Anstehende Besichtigungen',
    history: 'Durchgeführte Besichtigungen',
    all_planned: 'Anzahl:',
    no_results_found: 'Keine Ergebnisse gefunden',
    address: 'Adresse',
    create_slots: 'Slots erstellen',
    my_appointments: 'Meine Besichtigungen',
    my_smart_devices: 'Meine "Smart devices"',
    delete_appointments: 'Sind Sie sicher, dass Sie Termine löschen wollen?',
    search_appointments: 'Nach Adresse, Interessent oder Wohnungsname suchen',
    search_device: 'Nach Name, Gerätetyp oder Adresse suchen',
    name: 'Name',
    barrier_title: 'Barriere',
    device_type: 'Gerätetyp'
  }
}

export default message
