
import { defineComponent } from 'vue'
import { useMeta } from 'vue-meta'
import { useI18n } from 'vue-i18n'
import HomeSection from '@/components/sections/HomeSection.vue'
import Header from '@/components/sections/Header.vue'

export default defineComponent({
  setup () {
    const { t } = useI18n({ useScope: 'global' })
    useMeta({
      title: t('message.title.login')
    })
  },
  name: 'Home',
  components: {
    HomeSection,
    Header
  }
})
