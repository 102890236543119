<template>
  <div
    class="login"
    v-if="translateData"
  >
    <div class="login__title">
      Вхід
    </div>
    <div class="card card-container">
      <Form
        @submit="handleLogin"
        :validation-schema="schema"
      >
        <div class="form-group">
          <Field
            name="email"
            type="text"
            placeholder="Email"
            class="form-control"
          />
          <ErrorMessage
            name="email"
            class="error-feedback"
          />
        </div>
        <div class="form-group">
          <Field
            name="password"
            type="password"
            placeholder="Password"
            class="form-control password"
          />
          <ErrorMessage
            name="password"
            class="error-feedback"
          />
          <AlertBlock :message="message" />
        </div>

        <div class="form-group">
          <button
            class="login__button"
            :disabled="loading"
          >
            <span>Войти</span>
          </button>
        </div>
      </Form>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { Form, Field, ErrorMessage } from 'vee-validate'
import * as yup from 'yup'

import AlertBlock from '@/components/molecules/AlertBlock.vue'

export default defineComponent({
  name: 'Login',
  components: {
    Form,
    Field,
    ErrorMessage,
    AlertBlock
  },
  data () {
    const schema = yup.object().shape({
      email: yup.string().required('Login is required!'),
      password: yup.string().required('Password is required!')
    })
    return {
      translateData: {},
      loading: false,
      message: '',
      schema
    }
  },
  computed: {
    loggedIn () {
      return this.$store.state.auth.status.loggedIn
    }
  },
  methods: {
    handleLogin (user) {
      this.$store.dispatch('auth/login', user).then(
        (res) => {
          this.$router.push('/admin')
        },
        (error) => {
          this.loading = false
          this.message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
            error.message ||
            error.toString()
        }
      )
    }
  }
})
</script>

<style lang="scss" scoped>
.login {
  &__button {
    @include first-button;
    margin: 10px 0 0 0;

    &:hover {
      cursor: pointer;
      background: #10305c;
    }
    &:active {
      background: #1e88c3;
      box-shadow: 0px 2px 10px rgba(30, 136, 195, 0.25);
    }
  }

  &__title {
    font-size: 20px;
    padding: 35px 0 25px 0;
  }

  &__forgot-password {
    font-size: 16px;
    line-height: 19px;
    color: $thirdColor;

    &:hover {
      @include second-hover-link;
    }
  }

  &__recaptcha {
    padding-top: 20px;
  }
}
.form-group {
  position: relative;
  padding-bottom: 10px;
  margin-bottom: 10px;
  width: 300px;
  input {
    width: 300px;
  }
}
.error-feedback {
  color: #ff444f;
  font-size: 12px;
  position: absolute;
  bottom: -7px;
  left: 20px;
}

@media (max-width: $break-sm) {
  .login {
    &__button {
      width: 100%;
    }
  }
  .blur {
    width: 100%;
  }
  .form-group {
    position: relative;
  }
}
</style>
