
import { defineComponent } from 'vue'
import Logo from '@/assets/svg/logo.svg?inline'

export default defineComponent({
  name: 'Header',
  data () {
    return {
    }
  },
  components: {
    Logo
  },
  methods: {
    logout () {
      this.$store.dispatch('auth/logout').then(
        (res) => {
          this.$router.push('/')
        },
        (error) => {
          console.log(error)
        }
      )
    }
  }
})
